import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import { TimePatient } from '~/store/patientTrackerV2/types';

import useStyles from './PatientDetailsTab.styles';

interface PatientDetailsTabProps {
  stateId: string;
  timePatient: TimePatient;
  physicianId?: string;
  updatePhysician: (physicianId: string) => void;
}
export default ({ stateId, timePatient, physicianId: updatedPhysicianId, updatePhysician }: PatientDetailsTabProps) => {
  const classes = useStyles();
  const patientTempusId = timePatient.patient.id;
  const ptdTimePatientIds = timePatient.patientTrackingDetails
    .map(({ timePatientId }) => timePatientId)
    .filter(Boolean);

  const { currentInstitutionPhysicians } = useSelector((state: RootState) => state.patientTrackerV2[stateId]);
  const physicianOpts = currentInstitutionPhysicians?.map(({ id, name }) => ({ label: name, value: id })) || [];

  const updatedPhysician = currentInstitutionPhysicians?.find((physician) => physician.id === updatedPhysicianId);
  const selectedPhysician = updatedPhysician || timePatient.physician;

  const [physician, setPhysician] = React.useState<DropdownOption | null>(
    selectedPhysician ? { label: selectedPhysician.name, value: selectedPhysician.id } : null,
  );

  useEffect(() => {
    if (physician?.value) {
      updatePhysician(physician.value);
    }
  }, [physician]);

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        {patientTempusId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Tempus ID</div>
              <div>{patientTempusId}</div>
            </div>
          </div>
        )}
        {!!ptdTimePatientIds.length && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>TIME ID</div>
              {ptdTimePatientIds.map((timePatientId) => (
                <div key={timePatientId}>{timePatientId}</div>
              ))}
            </div>
          </div>
        )}
        {!ptdTimePatientIds.length && timePatient.billingId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Billing ID</div>
              <div>{timePatient.billingId}</div>
            </div>
          </div>
        )}
        <SingleSelectDropdown
          onChange={(physician) => setPhysician(physician)}
          value={physician}
          label="Physician"
          data-testid="update-overlay-physician"
          options={physicianOpts}
          data-pendo-id="update_pt-patient_status"
        />
      </div>
    </div>
  );
};
